<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
        <!-- <h5 class="font-weight-bold my-2">{{ title }}</h5> -->
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :action-label="actionLabel"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="mainTableNumberOfPages"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="mainTablePerPage"
        :show-pagination="false"
        :loading-message="loadingMessage"
        @before:refresh="onBeforeRefresh"
      >
      </base-table>
    </div>

    <div v-if="!onlyMainTable && !isFetching" class="card-body p-0">
      <base-table
        ref="teacherTable"
        :provider="teacherTableDataProvider"
        :fields="teacherTableFields"
        :current-page.sync="teacherTableCurrentPage"
        :number-of-pages="teacherTableNumberOfPages"
        :per-page="teacherTablePerPage"
        :sort-by.sync="sortByTeacher"
        :sort-desc.sync="sortDescTeacher"
        :show-action="false"
        :show-refresh="false"
      >
        <template #cell(create_plan)="{ item }">
          <span
            :class="{
              'text-success': item.create_plan === 'บันทึก',
              'text-danger': item.create_plan === 'ไม่บันทึก',
            }"
          >
            {{ item.create_plan }}
          </span>
        </template>

        <template
          v-for="valueField in valueFields"
          v-slot:[`cell(${valueField})`]="{ item }"
        >
          <div :key="`template-${valueField}`">
            {{
              item[`${valueField}`]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }}
          </div>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
// import { calculateRetireYear } from "../../helpers/datepicker-helper";
import { orderBy, groupBy, sum, map } from "lodash";
import formatDateMixin from "../../mixins/formatDateMixin";
import { Auth, Report } from "../../models";
import BaseTable from "./Base";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
    actionButtonText: {
      type: String,
      default: "เพิ่ม/แก้ไข",
    },
    actionLabel: {
      type: String,
      default: "เพิ่ม/แก้ไขข้อมูล",
    },
    seeDetail: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    onlyMainTable: {
      type: Boolean,
      default: false,
    },
    onlyOnePage: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: "กำลังดาวน์โหลดข้อมูล",
    },
  },

  components: {
    BaseTable,
  },

  data() {
    return {
      isFetching: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      sortBy: "",
      sortDesc: false,
      latestSortBy: null,
      latestSortDesc: null,
      sortByTeacher: "",
      sortDescTeacher: false,
      latestSortByTeacher: null,
      latestSortDescTeacher: null,
      perPage: 10,
      numberOfPages: 1,
      teacherTableCurrentPage: 1,
      teacherTableNumberOfPages: 1,
      teacherTablePerPage: 10,
      overviewTableData: [],
      teacherTableData: [],
      totalSum: null,
      teacherTableFields: [
        // { key: "order", label: "ลำดับ" },
        { key: "headquarter", label: "ภาค", sortable: true },
        { key: "division", label: "จังหวัด", sortable: true },
        { key: "station", label: "สถานี", sortable: true },
        { key: "user_name", label: "ยศ ชื่อ-สกุล", sortable: true },
        // { key: "rank", label: "ยศ" },
        // { key: "first_name", label: "ชื่อ" },
        // { key: "last_name", label: "สกุล" },
        { key: "position_name", label: "ตำแหน่ง", sortable: true },
        // { key: "phone_number", label: "เบอร์โทรศัพท์" },
        // { key: "retire_year", label: "ปีเกษียณ" },
        // { key: "medal", label: "ระดับเข็ม" },
        { key: "create_plan", label: "บันทึกแผนการสอน", sortable: true },
        { key: "user_status", label: "สถานะ", sortable: true },
        { key: "count_plan_students", label: "นักเรียนตามแผน", sortable: true },
        { key: "count_students", label: "นักเรียนจริง", sortable: true },
      ],
      valueFields: [
        "count_classes",
        "count_plan_students",
        "count_schools",
        "count_students",
        "count_teachers_complete_plan",
        "count_teachers_create_plan",
        "count_teachers_not_create_plan",
        "count_teachers_status_not_teach",
        "count_teachers_status_teach",
        "count_total_teachers",
      ],
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    mainTableNumberOfPages() {
      return this.onlyOnePage ? 1 : this.numberOfPages;
    },

    mainTablePerPage() {
      return this.onlyOnePage ? this.overviewTableData.length : this.perPage;
    },
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    formatPhoneNumber(text) {
      return text && text.length
        ? `${text.substring(0, 3)}-${text.substring(3, 6)}-${text.substring(
            6,
            10
          )}`
        : "-";
    },

    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.teacherTableCurrentPage = 1;
      this.teacherTableNumberOfPages = 1;

      this.latestSortBy = null;
      this.latestSortDesc = null;

      this.overviewTableData = [];
      this.teacherTableData = [];

      this.refresh();
    },

    onBeforeRefresh() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.teacherTableCurrentPage = 1;
      this.teacherTableNumberOfPages = 1;

      this.latestSortBy = null;
      this.latestSortDesc = null;

      this.overviewTableData = [];
      this.teacherTableData = [];
    },

    async fetch() {
      let data = [],
        numberOfPages = 1;

      const params = {},
        additionalParams = {};

      // if (!ctx.downloadAll) {
      //   this.$set(params, "limit", this.perPage);
      //   this.$set(params, "offset", (this.currentPage - 1) * this.perPage);
      // }

      if (this.params && this.params.mHeadquarterId) {
        this.$set(
          additionalParams,
          "mHeadquarterId",
          this.params.mHeadquarterId
        );
      }

      if (this.params && this.params.mDivisionId) {
        this.$set(additionalParams, "mDivisionId", this.params.mDivisionId);
      }

      if (this.params && this.params.mStationId) {
        this.$set(additionalParams, "id", this.params.mStationId);
      }

      if (this.params && this.params.fiscalYearFrom) {
        this.$set(
          additionalParams,
          "fiscalYearFrom",
          this.params.fiscalYearFrom
        );
      }

      if (this.params && this.params.termFrom) {
        this.$set(additionalParams, "termFrom", this.params.termFrom);
      }

      if (this.params && this.params.retireYearFrom) {
        this.$set(
          additionalParams,
          "retireYearFrom",
          this.params.retireYearFrom
        );
      }

      if (this.params && this.params.fiscalYearTo) {
        this.$set(additionalParams, "fiscalYearTo", this.params.fiscalYearTo);
      }

      if (this.params && this.params.termTo) {
        this.$set(additionalParams, "termTo", this.params.termTo);
      }

      if (this.params && this.params.retireYearTo) {
        this.$set(additionalParams, "retireYearTo", this.params.retireYearTo);
      }

      if (this.params && this.params.startDate) {
        this.$set(additionalParams, "startDate", this.params.startDate);
      }

      if (this.params && this.params.endDate) {
        this.$set(additionalParams, "endDate", this.params.endDate);
      }

      // if (this.sortBy) {
      //   this.$set(additionalParams, "order", this.sortBy);
      //   this.$set(additionalParams, "order_by", this.sortDesc ? "DESC" : "ASC");
      // }

      if (!this.overviewTableData.length) {
        this.isFetching = true;

        try {
          const promise = await Report.api().getTeachingOverview(
            { ...params, ...additionalParams },
            { save: false }
          );

          let { data: responseData = [] } = promise.response.data;

          let teacherData = [];

          this.teacherTableData = [];
          this.teacherTableCurrentPage = 1;

          let overviewTableData = responseData.map((record) => {
            const { teachers = [] } = record;

            record = {
              ...record,
              count_classes: record.count_classes || 0,
              count_plan_students: record.count_plan_students || 0,
              count_schools: record.count_schools || 0,
              count_students: record.count_students || 0,
              count_teachers_complete_plan:
                record.count_teachers_complete_plan || 0,
              count_teachers_create_plan:
                record.count_teachers_create_plan || 0,
              count_teachers_not_create_plan:
                record.count_teachers_not_create_plan || 0,
              count_teachers_status_not_teach:
                record.count_teachers_status_not_teach || 0,
              count_teachers_status_teach:
                record.count_teachers_status_teach || 0,
              count_total_teachers: record.count_total_teachers || 0,
            };

            teacherData = [
              ...teacherData,
              ...teachers.map((teacher) => {
                let user_name = "";

                if (teacher) {
                  const teacherRank = teacher.m_rank;

                  user_name = `${teacher.first_name} ${teacher.last_name}`;

                  if (teacherRank) {
                    user_name = `${teacherRank.m_rank_name} ${user_name}`;
                  }
                }

                return {
                  ...teacher,
                  headquarter: record.m_headquarters_name,
                  division: record.m_division_name,
                  station: record.m_station_name,
                  create_plan: teacher.create_plan ? "บันทึก" : "ไม่บันทึก",
                  // rank: teacher.m_rank ? teacher.m_rank.m_rank_name : "-",
                  // phone_number: teacher.phone_number
                  //   ? `${teacher.phone_number.substring(
                  //       0,
                  //       3
                  //     )}-${teacher.phone_number.substring(
                  //       3,
                  //       6
                  //     )}-${teacher.phone_number.substring(6, 10)}`
                  //   : "-",
                  // medal,
                  user_name,
                  count_classes: record.count_classes,
                  // count_plan_students: record.count_plan_students,
                  count_schools: record.count_schools,
                  // count_students: record.count_students,
                  count_teachers_complete_plan:
                    record.count_teachers_complete_plan,
                  count_teachers_create_plan: record.count_teachers_create_plan,
                  count_teachers_not_create_plan:
                    record.count_teachers_not_create_plan,
                  count_teachers_status_not_teach:
                    record.count_teachers_status_not_teach,
                  count_teachers_status_teach:
                    record.count_teachers_status_teach,
                  count_total_teachers: record.count_total_teachers,
                  // retire_year: teacher.retire_year || "-",
                };
              }),
            ];

            return {
              ...record,
            };
          });

          let groupKey = "m_headquarters_name";
          let keyFields = ["m_headquarters_name"];

          if (this.params.mHeadquarterId) {
            groupKey = "m_division_name";
            keyFields.push("m_division_name");
          }

          if (this.params.mDivisionId) {
            groupKey = "m_station_name";
            keyFields.push("m_station_name");
          }

          const groupedOverviewTableData = groupBy(
            overviewTableData,
            (record) => {
              return record[groupKey];
            }
          );

          overviewTableData = Object.values(groupedOverviewTableData).map(
            (groupData) => {
              const sampleData = groupData[0];

              return {
                ...keyFields.reduce((acc, keyField) => {
                  return {
                    ...acc,
                    [keyField]: sampleData[keyField],
                  };
                }, {}),
                count_classes: sum(map(groupData, "count_classes")),
                count_plan_students: sum(map(groupData, "count_plan_students")),
                count_schools: sum(map(groupData, "count_schools")),
                count_students: sum(map(groupData, "count_students")),
                count_teachers_complete_plan: sum(
                  map(groupData, "count_teachers_complete_plan")
                ),
                count_teachers_create_plan: sum(
                  map(groupData, "count_teachers_create_plan")
                ),
                count_teachers_not_create_plan: sum(
                  map(groupData, "count_teachers_not_create_plan")
                ),
                count_teachers_status_not_teach: sum(
                  map(groupData, "count_teachers_status_not_teach")
                ),
                count_teachers_status_teach: sum(
                  map(groupData, "count_teachers_status_teach")
                ),
                count_total_teachers: sum(
                  map(groupData, "count_total_teachers")
                ),
              };
            }
          );

          this.totalSum = {
            m_headquarters_name: "รวม",
            count_classes: 0,
            count_plan_students: 0,
            count_schools: 0,
            count_students: 0,
            count_teachers_complete_plan: 0,
            count_teachers_create_plan: 0,
            count_teachers_not_create_plan: 0,
            count_teachers_status_not_teach: 0,
            count_teachers_status_teach: 0,
            count_total_teachers: 0,
          };

          overviewTableData.forEach((element) => {
            this.totalSum.count_classes += element.count_classes;
            this.totalSum.count_plan_students += element.count_plan_students;
            this.totalSum.count_schools += element.count_schools;
            this.totalSum.count_students += element.count_students;
            this.totalSum.count_teachers_complete_plan +=
              element.count_teachers_complete_plan;
            this.totalSum.count_teachers_create_plan +=
              element.count_teachers_create_plan;
            this.totalSum.count_teachers_not_create_plan +=
              element.count_teachers_not_create_plan;
            this.totalSum.count_teachers_status_not_teach +=
              element.count_teachers_status_not_teach;
            this.totalSum.count_teachers_status_teach +=
              element.count_teachers_status_teach;
            this.totalSum.count_total_teachers += element.count_total_teachers;
          });

          this.overviewTableData = [...overviewTableData];
          this.teacherTableData = [...teacherData];

          this.teacherTableNumberOfPages = Math.ceil(
            this.teacherTableData.length / this.teacherTablePerPage
          );

          numberOfPages = Math.ceil(
            this.overviewTableData.length / this.perPage
          );
        } catch (error) {
          this.$toast.error(
            "ไม่สามารถดึงข้อมูลรายงานสรุปทั้งหมดได้ในขณะนี้ กรุณาลองใหม่ภายหลัง"
          );
        } finally {
          this.isFetching = false;
        }

        // if (!ctx.downloadAll) {
        //   this.numberOfPages = numberOfPages;
        // }
        this.numberOfPages = numberOfPages;
      }

      if (this.overviewTableData.length) {
        if (
          this.sortBy &&
          (this.sortBy !== this.latestSortBy ||
            this.sortDesc !== this.latestSortDesc)
        ) {
          this.latestSortBy = this.sortBy;
          this.latestSortDesc = this.sortDesc;

          this.overviewTableData = orderBy(
            this.overviewTableData,
            [this.sortBy],
            [this.sortDesc ? "desc" : "asc"]
          );
        }

        // const indexStart = (ctx.currentPage - 1) * ctx.perPage;

        // const endIndex = indexStart + ctx.perPage;

        data = [...this.overviewTableData];
      }

      return [...data, this.totalSum];
    },

    // async overviewTableDataProvider(ctx) {
    //   const indexStart = (ctx.currentPage - 1) * ctx.perPage;
    //   const endIndex = indexStart + ctx.perPage;
    //   return this.overviewTableData.length
    //     ? this.overviewTableData.slice(indexStart, endIndex)
    //     : [];
    // },

    async teacherTableDataProvider(ctx) {
      if (
        this.sortByTeacher &&
        (this.sortByTeacher !== this.latestSortByTeacher ||
          this.sortDescTeacher !== this.latestSortDescTeacher)
      ) {
        this.latestSortByTeacher = this.sortByTeacher;
        this.latestSortDescTeacher = this.sortDescTeacher;

        this.teacherTableData = orderBy(
          this.teacherTableData,
          [this.sortByTeacher],
          [this.sortDescTeacher ? "desc" : "asc"]
        );
      }

      const indexStart = (ctx.currentPage - 1) * ctx.perPage;
      const endIndex = indexStart + ctx.perPage;

      return this.teacherTableData.length
        ? this.teacherTableData.slice(indexStart, endIndex)
        : [];
    },

    onSeeDetail(item) {
      this.$emit("seeDetail", item);
    },

    editTeaching(item) {
      this.$emit("edit", item);
    },

    deleteTeaching(item) {
      this.$emit("delete", item);
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
