<template>
  <b-modal
    id="option-modal"
    v-model="$show"
    centered
    no-close-on-backdrop
    :title="title"
    @hidden="onHidden"
  >
    <b-form-group class="d-flex flex-row my-0 ml-3">
      <b-form-radio
        v-for="(option, index) in options"
        :key="`option-${index}`"
        v-model="selected"
        :name="name"
        :value="option.value"
        class="py-3"
        ><div>{{ option.label }}</div>
      </b-form-radio>
      <!-- <b-form-radio v-model="selected" name="some-radios" value="1" class="py-3"
        ><h4 class="font-weight-bold">ผู้บังคับการ</h4></b-form-radio
      > -->
    </b-form-group>

    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="dark" @click="cancel()"> ยกเลิก </b-button>

      <b-button size="sm" variant="primary" @click="onTypeSelected">
        <!-- <b-spinner
          v-if="printingItemId && printingItemId == item.id"
          small
          variant="light"
          label="printing..."
        ></b-spinner> -->
        <span>ยืนยัน</span>
      </b-button>
    </template>

    <!-- <generate-certificate
      :ref="`pdf_${item.id}`"
      :item="item"
      :format="format"
      :type-select="selected"
      @onProgressDone="onProgressDone"
    ></generate-certificate> -->
  </b-modal>
</template>

<script>
// import GenerateCertificate from "../report/GenerateCertificate";
import { Auth } from "../../models";

export default {
  // components: {
  //   GenerateCertificate,
  // },
  props: {
    title: String,
    name: String,
    show: Boolean,
    options: Array,
  },

  model: {
    prop: "show",
    event: "change",
  },

  data() {
    return {
      isSubmiting: false,
      // format: "",
      selected: "",
      // printingItemId: null,
    };
  },

  computed: {
    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("change", v);

        return this;
      },
    },
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    onTypeSelected() {
      this.$emit("selected:type", this.selected);
      this.$show = false;
    },

    // async printCertificate(item) {
    //   // สั่ง print รายละเอียด
    //   if (!this.selected) return this.$toast.error("กรุณาเลือกตำแหน่งผู้ลงนาม");

    //   this.printingItemId = item.id;

    //   const promise = await TeachingDare.api().findOne(
    //     item.id,
    //     {},
    //     { save: false }
    //   );

    //   if (promise) {
    //     const { data = {} } = promise.response.data;

    //     this.printItem = { ...data };

    //     this.$nextTick(() => {
    //       if (this.$refs[`pdf_${item.id}`]) {
    //         this.$nextTick(() => {
    //           const { $refs } = this.$refs[`pdf_${item.id}`];

    //           $refs.html2Pdf.generatePdf();
    //         });
    //       }
    //     });
    //   } else {
    //     this.$toast.error(
    //       "ไม่สามารถดึงข้อมูลแผนการสอนได้ กรุณาลองใหม่อีกครั้ง"
    //     );
    //   }
    // },

    // onProgressDone() {
    //   this.printingItemId = null;
    // },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.selected = "";
    },
  },
};
</script>
